<div class="page mb-5">
  <div class="text-center mb-5">
    <div class="ml-lg-5 mr-lg-5">
      <img
        class="mw-100"
        src="/assets/bk_calistay.png"
        alt="Bisiklet ve Kent Çalıştayı 2019 Logo"
      >
    </div>
  </div>

  <div class="text-center mb-5" *ngIf="environment.conclusionIsActive">
    <div class="ml-lg-5 mr-lg-5">
      <h3>
        Bisiklet ve Kent Çalıştayı 2019 12-13 Ocak tarihlerinde yapıldı.
      </h3>
      <h4>
        <a [routerLink]="['/sonuc']"><strong>Sonuç metni ve video için tıklayın!</strong></a>
      </h4>
    </div>
  </div>

  <div class="text-center mb-5 live-stream" *ngIf="environment.twitterStreamIsActive">
    <div class="ml-lg-5 mr-lg-5">
      <a class="twitter-timeline" data-dnt="true" href="https://twitter.com/search?q=from%3Adonkisotcycling%20filter%3Aperiscope" data-widget-id="921666036914515968">Tweets about from:donkisotcycling filter:periscope</a>
    </div>
  </div>

  <div class="row mb-lg-4">
    <div class="col-12 col-lg-6">
      <h1>Nedir?</h1>
      <ul>
        <li>
          Ulaşımda bisikletin önemi ve ulaşım sorunlarının çözümündeki rolünü konuşacağız.
        </li>
        <li>
          Metropolde yaşayan bisikletlilerin sorunları konuşacağız ve çözüm önerileri getireceğiz.
        </li>
        <li>
          İçinde bulunduğumuz küresel sistemde, bisikletin pozisyonunu tartışacağız.
        </li>
        <li>
          Aktivistlerin bisikleti nasıl kullandıklarını göreceğiz, bisikletin insanları nasıl aktivistleştirdiğini düşüneceğiz.
        </li>
        <li>
          Erişilebilirlik söz konusu olduğunda, tüm yoksayılanların nasıl benzer sorunlarla karşılaştığını anlayacağız.
        </li>
        <li>
          Sohbet edeceğiz, yeni insanlarla tanışacağız, yeni fikirler duyacağız.
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg-6">
      <h1>Nerede?</h1>
      <p>
        Bisiklet ve Kent Çalıştayı, Mimarlar Odası Büyükkent Şubesi Karaköy'de.
      </p>
      <div>
        <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=28.975804746150974%2C41.02249035690151%2C28.980284035205845%2C41.024311569876616&amp;layer=mapnik&amp;marker=41.02340096968456%2C28.978044390678406" style="border: 1px solid black; width: 100%; height: 300px"></iframe><br>
        <small><a href="https://www.openstreetmap.org/?mlat=41.02340&amp;mlon=28.97804#map=19/41.02340/28.97804">Haritayı büyüt</a></small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <h1>Nasıl?</h1>
      <p>
        Her gün dört adet sunum konusu belirlendi. Her sunumun sonunda soru-cevap süresi olacak. Ayrıca aralarda tanışmak, kaynaşmak ve muhabbeti koyulaştırmak için vaktimiz olacak.
      </p>
      <p>
        Programa <a routerLink="/program">buradan</a> ulaşabilirsiniz.
      </p>
      <p>
        Katılım ücretsizdir ve kayıt gerekmemektedir. Katılmak için bir bisikletinizin olması veya bisiklet sürmeyi biliyor olmanız gerekmez.
      </p>
    </div>
    <div class="col-12 col-lg-6">
      <h1>Ne zaman?</h1>
      <p>
        Bisiklet ve Kent Çalıştayı, 12-13 Ocak tarihlerinde, 13:00-19:00 saatleri arasında olacak.
      </p>
      <p>
        Ayrıca çalıştayın her anını <a href="https://twitter.com/search?q=%23bisikletkent" target="_blank" >#bisikletkent</a> etiketinden takip edebilirsiniz.
      </p>
      <p class="text-center">
        <a href="https://www.facebook.com/events/551206152010605/" target="_blank">Facebook Etkinliği</a>
      </p>
    </div>
  </div>
</div>
