/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organizers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./organizers.component";
var styles_OrganizersComponent = [i0.styles];
var RenderType_OrganizersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganizersComponent, data: {} });
export { RenderType_OrganizersComponent as RenderType_OrganizersComponent };
export function View_OrganizersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12 col-lg-6 p-5 text-center align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Don Ki\u015Fot Bisiklet Kolektifi"], ["class", "mw-100"], ["src", "assets/don-kisot-bisiklet-kolektifi-logo.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-12 col-lg-6 p-5 text-center align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Kuzey Ormanlar\u0131 Savunmas\u0131"], ["class", "mw-100"], ["src", "assets/kuzey-ormanlari-savunmasi-logo.png"]], null, null, null, null, null))], null, null); }
export function View_OrganizersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organizers", [], null, null, null, View_OrganizersComponent_0, RenderType_OrganizersComponent)), i1.ɵdid(1, 114688, null, 0, i2.OrganizersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganizersComponentNgFactory = i1.ɵccf("app-organizers", i2.OrganizersComponent, View_OrganizersComponent_Host_0, {}, {}, []);
export { OrganizersComponentNgFactory as OrganizersComponentNgFactory };
