<nav class="navbar navbar-expand-lg navbar-dark">
  <button
      (click)="isNavbarCollapsed = !isNavbarCollapsed"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
  ><span class="navbar-toggler-icon"></span></button>
  <div
      class="collapse navbar-collapse"
      id="navbarNavAltMarkup"
      [ngbCollapse]="isNavbarCollapsed"
  >
    <div class="navbar-nav mx-auto">
      <a
        class="nav-item nav-link"
        routerLink="/"
        routerLinkActive="active"
      >Bisiklet ve Kent Çalıştayı</a>
      <a
        class="nav-item nav-link"
        routerLink="/neden"
        routerLinkActive="active"
      >Neden?</a>
      <a
        class="nav-item nav-link"
        routerLink="/program"
        routerLinkActive="active"
      >Program</a>
      <a
        class="nav-item nav-link"
        routerLink="/paydaslar"
        routerLinkActive="active"
      >Paydaşlar</a>
      <a
        class="nav-item nav-link"
        routerLink="/belgeler"
        routerLinkActive="active"
      >Belgeler</a>
      <a
        class="nav-item nav-link"
        routerLink="/basindan"
        routerLinkActive="active"
      >Basından</a>
      <a
        class="nav-item nav-link"
        routerLink="/sonuc"
        routerLinkActive="active"
        *ngIf="environment.conclusionIsActive"
      >Sonuç</a>
    </div>
  </div>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>

<footer class="text-light">
  <div class="container p-3">
    <div class="row">
      <div class="col-6 text-center text-lg-left">
        <a class="text-light" href="https://www.facebook.com/events/1113964128738959/" target="_blank" title="Facebook etkinliği"><i class="fa fa-facebook-f"></i></a>
        &nbsp;
        <a class="text-light" href="https://twitter.com/search?q=%23bisikletkent" target="_blank" title="Çalıştayı Twitter'da takip et"><i class="fa fa-twitter"></i></a>
        &nbsp;
        <a class="text-light" href="https://github.com/egesu/bisiklet-ve-kent-calistayi-web" target="_blank"><i class="fa fa-github"></i></a>
      </div>
      <div class="col-6 text-center text-lg-right">
        CopyLeft&nbsp;&nbsp; <i class="fa fa-copyright" style="transform: rotate(180deg)"></i>
      </div>
    </div>
  </div>
</footer>
