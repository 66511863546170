<div class="page text-center">
  <p>
    Bisiklet ve Kent Çalıştayı hakkında çıkan haberleri burada bulabilirsiniz.
  </p>
  <ul>
    <li>
      <a href="http://www.mimarizm.com/etkinlikler/bulusmalar/bisiklet-ve-kent-calistayi-2019_129755" target="_blank">Mimarizm <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="http://www.yapi.com.tr/etkinlikler/bisiklet-ve-kent-calistayi-2019_169779.html" target="_blank">yapi.com.tr <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="http://www.sivilsayfalar.org/events/2-bisiklet-ve-kent-calistayi/" target="_blank">Sivil Sayfalar <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://bianet.org/bianet/kent/204240-bir-kisi-daha-bisiklete-binerse-ne-olur" target="_blank">Bianet <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://www.bisikletizm.com/don-kisot-bisiklet-kolektifi-ve-bisiklet-calistayi/" target="_blank">Bisikletizm <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://www.yesilist.com/cevre-dostu-etkinlik-bulteni-7-13-arasinda-gerceklesecek-16-yesil-etkinlik/" target="_blank">Yeşilist <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://www.birgun.net/haber-detay/bisiklet-calistayi-basliyor.html" target="_blank">BirGün <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://www.evrensel.net/haber/370587/bisiklet-calistayi-12-13-ocakta" target="_blank">Evrensel <i class="fa fa-external-link"></i></a>
    </li>
    <li>
      <a href="https://gaiadergi.com/bisiklet-calistayi-basliyor/" target="_blank">gaia dergi <i class="fa fa-external-link"></i></a>
    </li>
  </ul>

  <!-- <h3 class="p-3">Diğer medya</h3> -->


  <!-- <div class="video">
    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/sLYgtUqADOs?rel=0" frameborder="0" allowfullscreen></iframe>
  </div> -->
</div>
