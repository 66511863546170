<div class="page mb-5">
  <!-- <div class="mb-5">
    <iframe
        src="https://www.youtube-nocookie.com/embed/Ozfv-QNao84?rel=0"
        frameborder="0"
        style="width: 100%; height: 600px; max-height: 90vh;"
        allowfullscreen
    ></iframe>
  </div> -->

  <p>Bir kişi daha bisiklete binerse ne olur?</p>

  <p>Bir kişi fosil yakıtların tüketimine ortak olmaz. Atmosfere salınan ve gezegenimizi her gün biraz daha ısıtan gazlar azalır.  Unutma ki; kent merkezlerindeki karbonmonoksit (CO) emisyonlarının %70-90’ından, azotoksit (NO) emisyonlarinin %40-70’inden, hidrokarbon (HC) emisyonlarının yaklaşık % 50’sinden ve şehir bazında kurşun emisyonlarının %100’ünden özellikle motorlu taşıtlar sorumludur. Petrol ihtiyacının tetiklediği savaşın önündeki etten zincirin halkası olursun.
  Bisikletli kişi, süpermarketlerdeki, alışveriş merkezlerindeki tüketim çılgınlığının parçası olmak yerine gıda topluluklarının ve kooperatiflerin paydaşı olarak üretime yaklaşır. Sağlığını koruduğu için hastane kuyruklarından bir kişi azalır. Belki lastiği patladığında ona destek olan bir bisikletli ile tanışır, insanlara güvenmeyi öğrenir.</p>

  <p>Peki bin bisikletli daha düşünün. Hayatımızda bir kişinin yarattığı etkinin bin katını.
  Peki bir milyon katını? Gezegenden kaç motorlu araç eksildiğini hayal edin. Azalan sera gazlarını, sesi, trafiği; artan muhabbeti, güveni, ilişkileri...</p>

  <p>İşte hayatımıza bisikletin kattığı ve katabileceği bu konuları konuşmak üzere buluşuyoruz.</p>

  <ul>
  <li>Bisiklet ve Spor’da, amatör ruhumuzla sporcu yönümüzü konuşacağız. Saatlerce pedal mı çevirilir hiç?</li>
  <li>Bisiklet ve İstanbul’da, bu şehrin bisikletle nasıl daha güzel olduğunu konuşacağız.</li>
  <li>Bisiklet ve Yerel Yönetimler’de, “Bisikletsiz kent mi olurmuş?” sorusuna cevap üretmeye çalışacağız.</li>
  <li>Bisiklet ve Aktivizm’de, bisikletin gölgesinde sadece “İki teker-bir gidon mu varmış?” göreceğiz.</li>
  <li>Bisiklet ve Sağlık’da, gulutensiz değil bisikletsiz sağlık olmazı konuşacağız.</li>
  <li>Bisiklet ve Kadın'da, tüm kalıpyargıların ötesinde bisikletin cinsiyetsiz ve engelsiz nasıl sürülebileceğini konuşacağız.</li>
  <li>Bisiklet ve Ekoloji’de et kemikten ayrılır mı ayrılmaz mı göreceğiz.</li>
  <li>Bisiklet ve Erişilebilirlik’te, iki tekeri ile yüz beygiri tartıya koyacağız.</li>
  </ul>


  <p>Açık forumumuzda bisikletin ve bisikletlinin politikasını konuşacağız. Bisikletin iki teker bir gidondan ibaret olmadığını, sadece gezi veya spor aracı olmadığını, geçen seneki çalıştayın birikimiyle tartışıyoruz. Hayatımızı nasıl değiştirdiğini ve daha neleri değiştirebileceğini hayal ediyoruz.</p>

  <p><strong>Gezegeni tek başımıza kurtaramayacağımızı biliyoruz ama bizsiz kurtarılamayacağını da!</strong></p>
</div>
