<div class="page">
  <p>
    Bisiklet ve Kent Çalıştayı hakkında belgeleri aşağıdaki bağlantılardan indirebilirsiniz.
  </p>

  <ul>
    <li>
      <a href="assets/documents/basin-bulteni-2019.pdf" target="_blank">Basın Bülteni (PDF)</a>
    </li>
    <li>
      <a href="assets/documents/afis-2019.pdf" target="_blank">Afiş</a>
    </li>
    <li>
      <a href="assets/bk_calistay.png" target="_blank">Logo (PNG)</a>
    </li>
    <li>
      <a href="https://www.youtube.com/watch?v=82hJrb0X74s" target="_blank">Tanıtım videosu (Youtube)</a>
    </li>
  </ul>

  <div class="row">
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/ali-cenk-algun.jpg" class="mw-100" alt="Ali Cenk Algün">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/aydan-celik.jpg" class="mw-100" alt="Aydan Çelik">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/don-kisot-bisiklet-kolektifi.jpg" class="mw-100" alt="Don Kişot Bisiklet Kolektifi">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/gunes-uyaniker.jpg" class="mw-100" alt="Güneş Uyanıker">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/irem-cagil.jpg" class="mw-100" alt="İrem Çağıl">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/kuzey-ormanlari-savunmasi.jpg" class="mw-100" alt="Kuzey Ormanları Savunması">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/sarper-gunsal.jpg" class="mw-100" alt="Sarper Günsal">
    </div>
    <div class="col-12 col-sm-6 mb-5">
      <img src="assets/speakers/semih-ozdemir.jpg" class="mw-100" alt="Semih Özdemir">
    </div>
  </div>
</div>
