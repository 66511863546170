<div class="page mb-5">
  <h1>21-22 Ekim 2017 “Bisiklet ve Kent Çalıştayı” Sonuç Bildirisi</h1>

  <p>
  Don Kişot Bisiklet Kolektifi, 2015 Temmuz ayında Kadıköy’de Don Kişot İşgal Evi çağrısı ile kurulmuş, kurulduğu günden bugüne İstanbul içinde ve dışında birçok tur düzenlemiş, düzenlediği bu turlara toplamda binlerce bisiklet severin katılım gösterdiği, çevreci ve aktivist bir bisiklet ekibidir. Kolektif, sadece bisiklet turları düzenlemekle kalmayıp ritim ekibi, squat topluluğu, kadın komisyonu ve kültür sanat inisiyatifini de kendi içinde barındırıyor.
  </p>
  <p>
  Düzenlediği bisiklet turlarında her yaştan, cinsiyetten, performanstan kişilerin katılmasına özen gösteriyor ve bisikletin tüm ülkede yaygınlaşması için farkındalık yaratmaya çalışıyor.
  </p>
  <p>
  Kolektif, işleyiş olarak yatay bir örgütlenmeye sahip, bu nedenle lidersiz, başkansız bir şekilde düzenlediği forumlarda kolektif karar alma mekanizmasını işletiyor. Ekip, aynı zamanda çevre, kent, emek ve özgürlükler gibi başlıklarda diğer dayanışma ve kolektiflerle birlikte hareket ediyor.
  </p>

  <h3>Çalıştay</h3>

  <p>
    Don Kişot Bisiklet Kolektifi tarafından  21-22 Ekim 2017 tarihlerinde “Bisiklet ve Kent Çalıştayı 2017” gerçekleştirildi. Tasarım Atölyesi Kadıköy’de (TAK), Kadıköy Belediyesi Bisiklet Birimi, Sokak Bizim Derneği ve TMMOB ŞPO İstanbul Şube’nin de desteğiyle gerçekleştirilen çalıştaya İstanbul dışından Adana, Bartın, Bursa, Diyarbakır, Eskişehir, İzmir,  İzmit ve Şırnak olmak üzere 8 farklı ilden yaklaşık 300 kişi katıldı.
  </p>
  <p>
    Çalıştayda, şehirlerde bisikletin yaygınlaşması, gündelik yaşantıda bisikletin bir ulaşım aracı olarak kullanımının arttırılması ve bu bağlamda bisikletçilerin yan yana getirilmesi hedeflendi. Sekiz başlıkta yapılan sunuşlara ek olarak, serbest kürsü ve “Bisikletli’nin Yan yana Gelme Felsefesi” başlıklı forum gerçekleştirildi.
  </p>
  <p>
    Çalıştayın her aşaması Periscope aracılığıyla canlı olarak yayınlandı ve yine her aşaması görsel olarak arşivlendi.
  </p>
  <p>
    “Bisikletin Hukuku" bölümünde Aysu Melis Bağlan bisikletin hukuki dayanağı, bisikletçinin yasal hakları ve yerel yönetim örneği olarak Kadıköy Belediyesi ile yaşanan süreci ve uygulamaları aktardı.
  </p>
  <p>
    “Bisiklet ve Medya” bölümünde Bağış Erten ana akım medyanın dünü, bugünü ve bu değişimdeki kırılma noktalarından bahsederek, bisikletin bu medya organlarında hak ettiği değeri görmediğini vurguladı. Bunun değişimin ancak gündelik yaşamda daha fazla bisikletçinin görünür olmasıyla ve kendi hikayelerini yaratmaları ile mümkün olacağına değindi.
  </p>
  <p>
    “Bisiklet Kalp Dostudur” bölümünde Prof. Dr. Ali Serdar Fak, kalp ve damar hastalıklarıyla mücadele, bu hastalıların iyileştirilmesi sürecinde bisikletin katkısından bahsederken, dört mevsim bisiklete binen, işe bisikletle giden kişilerde kalp ve damar hastalıklarına yakalanma ihtimali gözle görülür derecede azalma gözlemlendiğinin altını çizdi. Benzer bir durumun kanser hastalığı içinde geçerli olduğundan bahsedilirken; haftada en az 3 gün, her defasında en az 45 dakika, düzenli ve kesintisiz hareket etmek gerektiğinden bahsetti.
  </p>
  <p>
    İlk günün son sunumu olan “Bisikletli Kadın Olmak” bölümünde İpek Saraç, karakter, estetik ve inatçılık açısından kadın ve bisikleti birbirine benzetirken, son yıllarda kadınların bisikletle yollarda daha rahat olmasına rağmen hala kadın bir bisikletçi olmanın ekstra zorlukları olduğundan bahsetti. Saraç, kişisel antrenman deneyimlerini katılımcılarla paylaşarak sunuşuna son verdi.
  </p>
  <p>
    İkinci günün ilk sunumu olan “Bisiklet dostu Kent Tasarımı” başlığında Arzu Erturan ve Doç. Dr. Kevser İsmet Üstündağ birlikte oturumu sunarken, bisiklet dostu bir kent tasarımının önemli olduğunu, ancak asıl çözümün yeni bir insan tasarımında yattığını belirttiler. İstanbul’da yaşayan 14 milyon vatandaşın sadece beş milyonu ehliyet sahibiyken ve bu kişilerin sadece 3.8 milyonunun taşıt kullandığını, geriye kalan 11 milyonun toplu taşıma kullanan vatandaşı mağdur etmekte olduğunu anlattılar. Yol ve köprü yapımının dünyanın hiçbir yerinde ulaşım sorununa çözüm olamadığını belirterek bisikletin bu konuda ciddi bir alternatif olduğu belirtildi.
  </p>
  <p>
    Engelli Hakları Forumu’ndan Mahmut Keçeci, “Erişilebilirlik” sunumunda, sorunun sadece engelliler için değil; yaya, bisikletli, çocuk arabalı her kişinin sorunu olduğunu belirtti. Devletin açtığı yarayı tedavi etmek yerine, yaralıyı cezalandırdığını belirten Keçeci, sorunun sadece sağlık sorununa indirgenmesinin çözüm olmadığını, milyonlarca engellinin evlerine hapsedildiğini söyledi. Motorlular duraksamadan yol alabilsin diye yayalar yolun altına veya üstüne yönlendirilirken, bu noktada bisikletlilerin zorluk yaşadığını belirterek “Ya hepimiz engelliyiz ya hiç birimiz engelli değiliz” sözleriyle sunumunu sonlandırdı.
  </p>
  <p>
    “İki Teker Üzerinden Dünyaya Bakmak” sunumunda Murat Sevinç, bisikletin icat edildiği 19. yy. sonlarından bu yana sadece ulaşım aracı olarak değil muhalif bir hareketin içinde de yer aldığını belirtti. Hayatta her şeyin politik olduğunu belirten Sevinç, “Bugünkü yaşamımız politik bir ağrı içindedir ve biz bunun içinde yaşıyoruz. Ulaşımda politik bir tercih olansa araba yerine bisikleti kullanmaktır” şeklinde sözlerine devam etti. Günümüzde süreci sağlıklı okuduğumuz takdirde bisikletin önümüzdeki süreçte daha da yaygınlaşacağının işaretlerine rastalayabileceğimizin ve bu işaretlerin neler olabileceğine dair devam eden sunuşun bu kısmı dinleyici tarafında ekstra ilgiyle karşılandı. Konuşmasının son kısmında kendisinin bisiklete başlama hikayesine değinen Sevinç, sürecin aslında yürümek fikriyle başladığını ancak daha sonra bu fikrin bisiklet sürmeye evrildiğinden bahsetti. Bisiklete başlamış olmanın, kendisindeki etkilerine de değinerek sunuşunu sonlandırdı.
  </p>
  <p>
    Çalıştayın son sunuşu “Bir Aktivizm Aracı Olarak Bisiklet” bölümünde, Don Kişot Bisiklet Kolektifi adına Emre Tepe, Merve Vardar ve Gürhan Güven birer sunuş gerçekleştirdiler. İlk olarak sunum yapan Emre Tepe, sokakların dili, anlamı, kamusal alanlarda var olmak ve müştereklere sahip çıkmanın önemini vurguladı. İkinci sunuşu yapan Merve Vardar, aktivist bir kadın bisikletçi olarak hukuki haklarımızın bile aslında uygulanır olmadığını, belediyelerin bisiklet yollarını süs ve reklam çalışması olarak yaptığını söyledi. Trafikte motorlu taşıt kullananlar şiddet ve yabancılaşmayla hayatlarına devam ederken, bisikletçilerin birbirlerine selam veren, sempatik, farkındalığı yüksek ve şiddetsiz bireyler olarak karşımıza çıktığını örneklendirdi. Son olarak söz alan Kaya, dünyada tecrübe edilmiş bisiklet aktivizm hareketlerinde bahsederken, “Bike Chic, Bike Hack, Bicycle Kitchen ve Critical Mass” hakkında detaylı bilgilendirme yaptı. Aktivizm odaklı bisiklet gruplarının ortak özellikleri; yatay olmak, lidersiz olmak, bir fiziki alana tıkılmamak, şiddet içermemek, doğrudan olmak, eşitlikçi, paylaşımcı, çevreci olmak, küreselleşme karşıtı olmak, vegan/vejetaryen olmak, homofobik olmamak, estetik bir biçimde politik olmak şeklinde özetlenebileceğini belirtti.
  </p>
  <p>
    Serbest kürsü kısmında ise gündelik deneyimlere özellikle bisiklet yollarının yetersizliği ve kullanışlı olmamasına değinildi.
  </p>
  <p>
    Forum kısmında bisikletlilerin yan yana durmasının önemi vurgulanırken, Critical Mass gibi araçlara sahip çıkılmasının ve güçlendirilmesinin önemi üzerinde duruldu. Ekip sürüşlerinin tekil sürüşlere nazaran daha güvenli, farkındalığı daha yüksek olduğu aktarıldı.
  </p>
  <h3>Sonuç</h3>
  <p>
    Çalıştayda iki gün süren söyleşi, forum ve çalışma grupları ile aşağıda belirtilen maddeleri hayata geçirme konusunda kararlar aldık:
  </p>

  <ul>
    <li>Sekiz sunuş başlığına paralel olarak çalışma grupları oluşturulacak ve bu çalışma gruplarının bir ay içerisinde hazırlayacakları deklarasyonlar ile bir kitap çıkartılacak.</li>
    <li>Çalışma gruplarının çıkartacağı kitap ücretsiz olarak yayınlanacak, basılı ve dijital formatı en yaygın dağıtım alanına sunulacak.</li>
    <li>Çalıştay boyunca çekilen görüntüler en hızlı şekilde internet ortamında yaygınlaşacak.</li>
    <li>Bu çalıştayın benzerinin diğer illerde de örgütlenmesi için yerellerdeki bisiklet ekiplerine destek sunulacak.</li>
    <li>İstanbul’daki üniversitelerde bu çalıştayın daha küçük formları yapılacak.</li>
    <li>Çalıştay her sene tekrarlanacak ve gelişmeler gözlemlenecek.</li>
  </ul>

  <div class="text-center">
    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/q3AQGwa3phc" frameborder="0" allowfullscreen style="max-width: 100%;"></iframe>
  </div>
</div>
