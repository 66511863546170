<div class="page">
  <div class="row">
    <div class="col-12 col-lg-6 p-5 text-center align-self-center">
      <img class="mw-100" src="assets/don-kisot-bisiklet-kolektifi-logo.jpg" alt="Don Kişot Bisiklet Kolektifi">
    </div>
    <div class="col-12 col-lg-6 p-5 text-center align-self-center">
      <img class="mw-100" src="assets/kuzey-ormanlari-savunmasi-logo.png" alt="Kuzey Ormanları Savunması">
    </div>
    <!--
    <div class="col-12 col-lg-6 p-5 text-center align-self-center">
      <img class="mw-100" src="assets/kadikoy-bisiklet-birimi-logo.jpg" alt="Kadıköy Bisiklet Birimi">
    </div>
    <div class="col-12 col-lg-6 p-5 text-center align-self-center">
      <img class="mw-100" src="assets/sokak-bizim-dernegi-logo.jpg" alt="Sokak Bizim Derneği">
      <h5 class="mt-3">Sokak Bizim Derneği</h5>
    </div> -->
  </div>
</div>
