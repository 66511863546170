<div class="page">
  <h3 class="mb-4 mt-2 text-center">
    Bisiklet ve Kent Çalıştayı 2019 Programı
  </h3>

  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th>12 Ocak Cumartesi</th>
        <th>13 Ocak Pazar</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>12:00 - 12:30</th>
        <td><strong>Açılış</strong></td>
        <td><strong>Açılış</strong></td>
      </tr>
      <tr>
        <th>13:00 - 13:40</th>
        <td><br><strong>Bisiklet ve Spor</strong></td>
        <td><br><strong>Bisiklet ve Sağlık</strong></td>
      </tr>
      <tr>
        <th>13:40 - 14:20</th>
        <td><br><strong>Bisiklet ve İstanbul</strong></td>
        <td><br><strong>Bisiklet ve Kadın</strong></td>
      </tr>
      <tr>
        <th>14:20 - 15:00</th>
        <td><br><strong>Bisiklet ve Yerel Yönetimler</strong></td>
        <td><br><strong>Bisiklet ve Ekoloji</strong></td>
      </tr>
      <tr>
        <th>15:00 - 16:00</th>
        <td>Kokteyl</td>
        <td>Kokteyl</td>
      </tr>
      <tr>
        <th>16:00 - 16:40</th>
        <td><br><strong>Bisiklet ve Aktivizm</strong></td>
        <td><br><strong>Bisiklet ve Erişilebilirlik</strong></td>
      </tr>
      <tr>
        <th>16:40 - 18:00</th>
        <td><strong>Forum</strong></td>
      </tr>
      <tr>
        <th></th>
        <td>!Parti!</td>
      </tr>
    </tbody>
  </table>
</div>
